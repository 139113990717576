
import { STATUS_DRAFT } from "@/constants/common";
export default {
  type: "esigns",
  code:null,
  name: null,
  description: null,
  onespan_id: null,
  status: STATUS_DRAFT,
  documents: null,
  relationshipNames: [
    "organization",
  ],
  organization: {
    type: "organizations",
    id: null,
  },
};
