<template>
  <div class="elite-tabs-wrapper-content">
    <h3>{{ `${esign.name}` }}</h3>
    <div class="col-12">
      
      <dl class="row">
        <dt class="col-sm-4">{{ $t("COMMON.CODE") }}</dt>
        <dd class="col-sm-8" v-if="esign?.code">
          {{ esign?.code }}
        </dd>
      </dl>

      <dl class="row">
        <dt class="col-sm-4">{{ $t("COMMON.EXCERPT") }}</dt>
        <dd class="col-sm-8" v-if="esign?.description">
          {{ esign?.description }}
        </dd>
      </dl>

      <dl class="row">
        <dt class="col-sm-4">{{ $t("ESIGNS.EMAIL_MESSAGE") }}</dt>
        <dd class="col-sm-8" v-if="esign?.email_message">
          {{ esign?.email_message }}
        </dd>
      </dl>
      
      <dl class="row">
        <dt class="col-sm-4">{{ $t("COMMON.STATUS") }}</dt>
        <dd class="col-sm-8" v-if="esign?.status">
          <status-badge :status="esign.status" />
        </dd>
      </dl>


      <dl
        class="row"
        v-if="$currentUserCan($permissions.PERM_VIEW_ANY_ORGANIZATIONS)"
      >
        <dt class="col-sm-4">{{ $t("COMMON.ORGANIZATION") }}</dt>
        <dd class="col-sm-8">
          <organization :organization="esign.organization" />
        </dd>
      </dl>

      <dl class="row">
        <dt class="col-sm-4">{{ $t("COMMON.CREATED_AT") }}</dt>
        <dd class="col-sm-8">
          {{ esign.created_at | moment("LLLL") }}
        </dd>
      </dl>

      <dl class="row">
        <dt class="col-sm-4">{{ $t("COMMON.UPDATED_AT") }}</dt>
        <dd class="col-sm-8">
          {{ esign.updated_at | moment("LLLL") }}
        </dd>
      </dl>
    </div>
  </div>
</template>

<script>
import StatusBadge from "../components/StatusBadge.vue";
export default {
  components: {
    StatusBadge
  },
  name: "esign-view-global",
  props: ["esign"],
  methods: {
    esignUpdated() {
      this.$emit("esignUpdated", true);
    },
  },
  watch: {
    esign(esign) {},
  },
};
</script>
